import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
const Header = () => {
    return ( 
        <nav className="page-header" style={{marginTop:isMobile?"29px":"",position:"fixed"}}>
            <div className="row">
                <div className="col-6">
                    <img src="../logo.png" style={{width:"40px"}} alt="Logo"></img>
                    {/* <span className="logo-name">ပွဲစားကြီး Pay</span> */}
                </div>
                <div className="col-6" style={{textAlign:"right",color:"white"}}>
                    <Link to={"/credit"} style={{fontSize:"10px",color:"#eee",textDecoration:"none",marginRight:"5px",color:"gold"}}>
                        <i className="bx bx-dollar" style={{marginRight:"1px",color:"gold"}}></i>
                        အကြွေး
                    </Link>
                    <Link to={"/app-download"} style={{color:"#eee",textDecoration:"none"}}>
                        <i className="bx bx-cloud-download" style={{marginRight:"5px"}}></i>
                        App
                    </Link>
                    <a href="/" className="home-reload">
                        <i className="lni lni-reload"></i>
                    </a>
                </div>
            </div>
        </nav>
     );
}
 
export default Header;