import {  Form, Spinner } from 'react-bootstrap';
import Layout2 from '../layouts/Layout2';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Transaction = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [from, setFrom] = useState("");
    const [to, setTo]     = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const navigate = useNavigate();
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    }
    const fetchData = async () => {
        setIsLoading(true);
        const paramArray = {
            "from" : from,
            "to":to
        };
        try{
            const response = await fetch(endpointURL+"/api/v1/getTransactions",{
                method:"POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                },
                body: JSON.stringify(paramArray),
            });
            if(!response.ok)
            {
               console.log("error resp");
            }
            const result = await response.json();
            if(result.status == true)
            {
                setFrom(result.date.from);
                setTo(result.date.to);
                setData(result.data);
            }else
            {
                console.log("response fail");
            }
        }catch(error){
            console.log("error catch")
        }
        setIsLoading(false);
    }
    console.log(data);
    const viewTransaction = (data) => {
        navigate('/transaction-view', {state:data});
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
              });
            navigate("/");
        }
        fetchData();
    },[]);
    return ( 
        <>
            <Layout2 pageType="မှတ်တမ်းများ">
                <Helmet>
                    <title>မှတ်တမ်းများ</title>
                </Helmet>
                <Form className='row mb-2' onSubmit={ handleSearch}> 
                    <Form.Group className='col-6'> 
                        <Form.Label>From:</Form.Label> 
                        <Form.Control type="date" defaultValue={from} onChange={(e)=> setFrom(e.target.value)} className='bg-dark' style={{color:"rgb(204,204,204)"}}/> 
                    </Form.Group> 
                    <Form.Group className='col-6'> 
                        <Form.Label>To:</Form.Label> 
                        <Form.Control type="date" defaultValue={to} onChange={(e)=> setTo(e.target.value)}  className='bg-dark' style={{color:"rgb(204,204,204)"}}/> 
                    </Form.Group> 
                    <Form.Group className='col-12 mt-2' style={{textAlign:'center'}}> 
                        <button className="btn btn-outline-primary"  type="submit"> 
                        <i className='lni lni-search text-primary'></i>  
                        ရှာမည်
                        </button> 
                    </Form.Group> 
                </Form>
                    {
                        isLoading ? 
                            <div style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        :
                            data.length > 0 ?
                                data.map((list)=>(
                                    <table key={list.id} onClick={(e) => viewTransaction(list)} className="table table-borderless outline-secondary" border="0" style={{width:"100%",borderRadius:"20px",fontSize:"13px"}} >
                                        <tbody>
                                            <tr>
                                                <td rowSpan={2} style={{maxWidth:"30px",verticalAlign:"center"}}><img src={`./${list.image}`} style={{width:"40px",marginTop:"3px"}} alt="Logo"></img></td>
                                                <td rowSpan={1} style={{textAlign:"center",minWidth:"40%"}}>
                                                    {list.pay_name}
                                                    <span style={{color:"green",marginLeft:"6px"}}>
                                                        {
                                                        list.status_id == "2"  && list.credit == "0" ? " ( Paid )":""
                                                        }
                                                        {
                                                        list.status_id == "3"  && list.credit == "0" ? " ( Paid )":""
                                                        }
                                                    </span>
                                                    <span style={{color:"red",marginLeft:"6px"}}>  
                                                        {
                                                        list.status_id == "2"  && list.credit == "1" ? " ( Credit )":""
                                                        }
                                                        {
                                                        list.status_id == "3"  && list.credit == "1" ? " ( Credit )":""
                                                        }
                                                    </span>
                                                </td>
                                                <td rowSpan={1} style={{textAlign:"right"}}>{list.kyat} MMK</td>
                                            </tr>
                                            <tr>
                                                <td rowSpan={1} style={{textAlign:"center"}}>{list.date} {list.time} </td>
                                                <td rowSpan={1} style={{textAlign:"right",color:list.status_id == 1?"red":list.status_id == 2?"green":list.status_id == 3?"green":"red"}}>
                                                    {list.status_name}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                )) 
                        :<div style={{textAlign:"center"}}>မှတ်တမ်းမရှိပါ...</div>
                    }
            </Layout2>
        </>
     );
}
 
export default Transaction;