import {   Spinner, Table } from 'react-bootstrap';
import Layout2 from '../layouts/Layout2';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const CommissionWithdrawl = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const navigate  = useNavigate();
    const commissionWithdrawlFetch = async () => {
        try{

            const response = await fetch(endpointURL+'/api/v1/commission-withdrawl',{
                  headers:{
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer '+localStorage.getItem('token'),
                 },
              });
            if(!response.ok)
            {
               console.log("error resp");
            }
            const result = await response.json();
            if(result.status == true)
            {
                setData(result.data);
            }else
            {
                console.log("response fail");
            }
        }catch(error){
            console.log("error catch")
        }
        setIsLoading(false);
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
        setIsLoading(true);
        commissionWithdrawlFetch();
    },[]);
    return ( 
        <>
            <Layout2 pageType="ထုတ်ယူပြီးကော်မရှင်">
                <Helmet>
                    <title>ပွဲစားကြီး Pay - ရရှိပြီးကော်မရှင်</title>
                </Helmet>
               
                <h6>ထုတ်ယူပြီး ကော်မရှင်</h6>
                    {
                        isLoading ? 
                            <div style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        : data.length > 0 ?
                            <Table striped  hover variant="dark" className="mt-2">
                                <tbody>
                                    {
                                        data.map((list)=>(
                                            <tr key={list.id}>
                                                <td>{list.date} {list.time}</td>
                                                <td>{list.amount} MMK</td>
                                            </tr>
                                        )) 
                                    }
                                </tbody>
                            </Table>
                        :
                        (
                            <div style={{textAlign:"center"}}>ထုတ်ယူပြီး ကော်မရှင် မရှိပါ...</div>
                        )
                    }
            </Layout2>
        </>
     );
}
 
export default CommissionWithdrawl;