import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MyanmarInfo from "./MyanmarInfo";
import Skeleton from "react-loading-skeleton";
const KyatPayWonYu = ({bank_id}) => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const fileTypes = ["JPEG","JPG", "PNG"];
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const [exchangerates,setExchangeRates] = useState([]);
    const [exchangeIsLoading,setExchangeIsLoading] = useState(false);
    const [account,setAccount] = useState(true);
    const [qr,setQr] = useState(false);
    const navigate = useNavigate();
    const [errors,setErrors] = useState({
        receipt_type:"",
        won:"",
        price:"",
        kyat:"",
        bank_id:"",
        korea_bank_name:"",
        account_no:"",
        name:"",
        uploadFile:null,
        qr_image:null
    });
    const [values,setValues] = useState({
        receipt_type: "Account",// Account or Qr
        payment_type_id: 7, //banking 
        won:"",
        price:"",
        kyat:"",
        bank_id:bank_id,
        korea_bank_name:"",
        account_no:"",
        name:"",
        uploadFile:null,
        qr_image:null
  });
  const handleChangeNumber = async(e) => {
    const key = e.target.id;
    const value = e.target.value;
   // console.log(value);
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    setExchangeIsLoading(true);
        const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=1',{ 
            headers: { 
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json(); 
        if(result.status === true)
        {
            setExchangeRates(result.data);
            if(key === "won")
                {
                    /**
                     * loop array and filter for typing won
                     */
                    /**
                     * get first price
                     */
                    const exchangeArray = exchangerates.filter((list) => {
                        return list.exchange_rate_type_id === 1;
                    });
                    if(exchangeArray.length > 0)
                    {
                        setValues(values => ({
                            ...values,
                            price: exchangeArray[0]?.price
                        }));
                        setValues(values => ({
                            ...values,
                            kyat:  parseInt((values.won * exchangeArray[0]?.price))
                        }));
                    }else
                    { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                        setValues(values => ({
                            ...values,
                            price: ""
                        }));
                        setValues(values => ({
                            ...values,
                            kyat: ""
                        }));
                    }
            }
            if(key === "kyat")
            {
                /**
                 * loop array and filter for typing kyat
                 */
                const exchangeArray = exchangerates.filter((list) => {
                    return    list.exchange_rate_type_id === 1;
                });
                /**
                 * array ၂ ခု ပေါ်လာရင် ပထမတခုပဲယူသည်
                 * backend ကလဲ ထည့်တဲ့အချိန် စစ်ဆေးရမည်
                 */
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        won: parseInt(values.kyat / exchangeArray[0]?.price)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        won: ""
                    }));
                }
            }
            setExchangeIsLoading(false);
        }
    
  };
    const handleChangeDigit = async (e) => {
        const key = e.target.id;
        const value = e.target.value;
    // console.log(value);
        // Allow only numbers and a single decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            setValues(values => ({
                ...values,
                [key]: value,
            }));
        }
    }
    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value
        //console.log(key+"/"+value);
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    const handleFile = (file) => {
        setValues(values => ({
            ...values,
            uploadFile: file,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(values);
        await Swal.fire({
            title: "<span style='color:#000'>ငွေလွှဲမှာ သေချာလား?</span>",
            showCancelButton: true,
            width:350,
            confirmButtonText: "အတည်ပြုမည်",
        }).then(async (result) => {
            if(result.isConfirmed) {
                if(!submitted){
                    setSubmitted(true);
                    try{
                        const formData = new FormData();
                        formData.append('payment_type_id', values.payment_type_id);
                        formData.append('won', values.won);
                        formData.append('price', values.price);
                        formData.append('kyat', values.kyat);
                        formData.append('bank_id', values.bank_id);
                        formData.append('korea_bank_name', values.korea_bank_name);
                        formData.append('account_no', values.account_no);
                        formData.append('name', values.name);
                        formData.append('uploadFile', values.uploadFile);
                        formData.append('qr_image', values.qr_image);
                        formData.append('receipt_type', values.receipt_type);
                        const response = await fetch(endpointURL+"/api/v1/transactionInsert", {
                            method: 'POST',
                            headers:{
                                //'Content-Type': 'application/json',
                                'Authorization':  'Bearer '+localStorage.getItem('token'),
                            },
                            body: formData,
                        });
                        if(!response.ok)
                        {
                            throw new Error('Request failed');
                        }
                        const resp = await response.json();
                        //console.log(resp);
                        if(resp.status === true)
                        {
                            setValues({
                                    won:"",
                                    price:"",
                                    kyat:"",
                                    bank_id:bank_id,
                                    korea_bank_name:"",
                                    account_no:"",
                                    name:"",
                                    uploadFile:null,
                                    receipt_type:"",
                                    qr_image:null
                              });
                              Swal.fire({
                                title: resp.msg,
                                width: 300, // Set the width
                              });
                              //console.log(resp.data);
                              navigate('/transaction-view', {state:resp.data});
                        }else{
                            setErrors(errors => ({
                                ...errors,
                                won: resp.data.won,
                                price:resp.data.price,
                                kyat:resp.data.kyat,
                                korea_bank_name: resp.data.korea_bank_name,
                                account_no: resp.data.account_no,
                                name:resp.data.name,
                                uploadFile:resp.data.uploadFile,
                                qr_image:resp.data.qr_image

                            }));
                        }
                    }catch(error){
                        Swal.fire({
                            title: error,
                            width: 300, // Set the width
                          });
                    }
                    setSubmitted(false);
                }
            }
        });
       
        setIsLoading(false);
    }
    const handleAccount = () => {
        setAccount(true);
        setQr(false);
        setValues(values => ({
            ...values,
            receipt_type: "Account",
        }));
        setValues(values => ({
            ...values,
            qr_image: null,
        }));
    }
    const handleQr = () => {
        setAccount(false);
        setQr(true);
        setValues(values => ({
            ...values,
            receipt_type: "Qr",
        }));
    }
    const handleReceiptFile = (file) => {
        setValues(values => ({
            ...values,
            qr_image: file,
        }));
    };
    useEffect(() =>{
        console.count("kyat pay won yu currency rate :");
       // handleFetchExchangeRate();
    },[])
    return ( 
        <>
            <MyanmarInfo bank_id={bank_id} />
            <form className="row" onSubmit={handleSubmit}>
                <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"16px"}}>ကိုးရီယား ဘဏ်အကောင့်ဖြင့်ငွေယူ </p>
                <div className="form-group mt-0">
                    <div className="col-12">
                        <label htmlFor="kyat" style={{fontWeight:"bold"}}>ပေးပို့ငွေ (ကျပ်)  <b className="text-danger">*</b></label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark text-success"><img src="/myanmar.png" /> </span>
                            <input type="number" id="kyat" value={values.kyat} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="kyat" style={{color:"red"}}>{errors.kyat}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="price" style={{fontWeight:"bold"}}>လက်ရှိငွေလဲနှုန်း <b className="text-danger">*</b>
                            {
                                exchangeIsLoading ?<Skeleton width={80} height={10} style={{ backgroundColor: 'green',textAlign:'center' }} />:<span style={{padding:"10px"}}>&nbsp;</span>
                            }
                        </label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark"><i className="lni lni-dollar text-success"></i> </span>
                            <input type="text" id="price" readOnly value={values.price}   className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="price" style={{color:"red"}}>{errors.price}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="won" style={{fontWeight:"bold"}}>လက်ခံငွေပမာဏ (ဝမ်) <b className="text-danger">*</b></label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark text-success"><img src="/southkorea.png" /> </span>
                            <input type="number" id="won" value={values.won} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="won" style={{color:"red"}}>{errors.won}</label>
                    </div>
                </div>
                <div className="form-group row mx-auto">
                    <div className="col-6" style={{textAlign:"center"}}>
                        <div className="input-group"> 
                            <button  type="button" onClick={handleAccount} className={`btn btn-md  ${account?'btn-success':'btn-outline-success'}  w-100`}>Account <i className={` ${account?'lni lni-checkmark':''}`}></i></button>
                        </div>
                    </div>
                    <div className="col-6" style={{textAlign:"center"}}>
                        <div className="input-group"> 
                            <button  type="button" onClick={handleQr} className={`btn btn-md  ${qr?'btn-success':'btn-outline-success'} w-100`}>QR  <i className={` ${qr?'lni lni-checkmark':''}`}></i></button>
                        </div>
                    </div>
                </div>
                {
                    account ?
                    <>
                        <div className="form-group">
                            <div className="col-12">
                                <label htmlFor="korea_bank_name" style={{fontWeight:"bold"}}>ကိုးရီယားဘဏ်အမျိုးအစား <b className="text-danger">*</b></label>
                                <div className="input-group mt-2"> 
                                    <span className="input-group-text bg-dark"><i className="lni lni-home text-success"></i> </span>
                                    <input type="text" id="korea_bank_name" value={values.korea_bank_name} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                </div>
                                <label htmlFor="korea_bank_name" style={{color:"red"}}>{errors.korea_bank_name}</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-12">
                                <label htmlFor="account_no" style={{fontWeight:"bold"}}>ကိုးရီယားအကောင့်နံပါတ် <b className="text-danger">*</b></label>
                                <div className="input-group mt-2"> 
                                    <span className="input-group-text bg-dark"><i className="lni lni-more text-success"></i> </span>
                                    <input type="text" id="account_no" value={values.account_no} onChange={handleChangeDigit}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                </div>
                                <label htmlFor="account_no" style={{color:"red"}}>{errors.account_no}</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-12">
                                <label htmlFor="name" style={{fontWeight:"bold"}}>အမည် <b className="text-danger">*</b></label>
                                <div className="input-group mt-2"> 
                                    <span className="input-group-text bg-dark"><i className="lni lni-user text-success"></i> </span>
                                    <input type="text" id="name" value={values.name} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                </div>
                                <label htmlFor="name" style={{color:"red"}}>{errors.name}</label>
                            </div>
                        </div>
                    </>:
                    <>
                        <div className="form-group mt-2">
                            <label style={{fontWeight:"bold"}} className="mb-2">ငွေလက်ခံ QR <b className="text-danger">*</b></label>
                            <FileUploader handleChange={handleReceiptFile} name="file" types={fileTypes}></FileUploader>
                            <label  style={{color:"red"}}>{errors.qr_image}</label>
                        </div>
                    </>
                }
                <div className="form-group">
                    <label style={{fontWeight:"bold"}} className="mb-2">ကျပ်ငွေလွှဲ စလစ် <b className="text-danger">*</b></label>
                    <FileUploader handleChange={handleFile} name="file" types={fileTypes}></FileUploader>
                    <label  style={{color:"red"}}>{errors.uploadFile}</label>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-success"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading..":"ရှေ့ဆက်မည်"}</button>
                    </div>
                </div>
            </form>
        </>
     );
}
 
export default KyatPayWonYu;