import React, { useEffect } from 'react';
import Layout from './layouts/Layout';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TelegramLoginButtonComponent from './components/TelegramLoginButton';
function App() {
  let id = "all";
  let imgSize= {
      width: "95%",
      height:"60px",
      boxShadow: "0 5px 10px #888888",
      borderRadius:"10px"
  };     
  useEffect(()=>{
    
  },[]);  
  return (
        <Layout>
           <Helmet>
                <title>ပွဲစားကြီး Pay</title>
          </Helmet>
          <div style={{textAlign:"center"}}>
            <TelegramLoginButtonComponent />
          </div>
          <div className='row mt-2'>
                <div className="col-12 mb-3 text-center">
                    <h6 style={{color:"#eee",fontWeight:"bold"}}>မင်္ဂလာပါ {localStorage.getItem('name')} </h6>
                    <p>ပွဲစားကြီး Pay မှကြိုဆိုပါတယ်</p>
              </div>
              <div className='col-4' style={{textAlign:"center"}}>
                  <Link to={"/won-to-kyat"} className='link'>
                    <img src="won_to_kyat.png" style={imgSize} alt='lucky type'></img>
                  </Link>
                  <p style={{fontWeight:"bold",fontSize:"12px"}}>ဝမ်ပေးကျပ်ယူ</p>
              </div>
              <div className='col-4' style={{textAlign:"center"}}>
                  <Link to={"/kyat-to-won"} className='link'>
                    <img src="kyat_to_won.png" style={imgSize} alt='lucky type'></img>
                  </Link>
                  <p style={{fontWeight:"bold",fontSize:"12px"}}>ကျပ်ပေးဝမ်ယူ</p>
              </div> 
              <div className='col-4' style={{textAlign:"center"}}>
                  <Link to={"/won-to-kyat-form/6"} className='link'>
                    <img src="bath_to_kyat.png" style={imgSize} alt='lucky type'></img>
                  </Link>
                  <p style={{fontWeight:"bold",fontSize:"12px"}}>ဘတ်တင်ပေး</p>
              </div> 
              <div className="col-12 mt-3 text-center">
                    <h6 style={{color:"#eee",fontWeight:"bold"}}>ဆက်သွယ်ရန်ဖုန်း</h6>
              </div>
              <div className="col-12  p-2">
                  <span style={{color:"#eee",marginLeft:"15px"}}> 01073481563</span>
                  <a href="tel:01073481563" className="btn btn-md btn-outline-success" style={{float:"right",width:"130px"}}>
                      <i className="lni lni-phone text-success"></i> Call
                  </a>
              </div>
              <div className="col-12  p-2">
                  <span style={{color:"#eee",marginLeft:"15px"}}> 01073481563</span>
                  <a href="https://telegram.me/+821073481563" target="_blank" className="btn btn-md btn-outline-success" style={{float:"right",width:"130px"}}>
                      <i className="lni lni-phone text-success"></i> Telegram
                  </a>
              </div>            
          </div> 
        </Layout>
  );
}

export default App;
