import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import KoreaBankIn from "./KoreaBankInfo";
import Skeleton from "react-loading-skeleton";
const Wave = ({payment_type_id}) => {
 
  const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
  const fileTypes = ["JPEG","JPG", "PNG"];
  const [isLoading,setIsLoading] = useState(false);
  const [submitted,setSubmitted] = useState(false);
  const [exchangerates,setExchangeRates] = useState([]);
  const navigate = useNavigate();
  const [account,setAccount] = useState(true);
  const [password,setPassword] = useState(false);
  const [payaccount,setPayAccount] = useState(true);
  const [exchangeIsLoading,setExchangeIsLoading] = useState(false);
  const [qr,setQr] = useState(false);
  const [paid_request,setPaidRequest] = useState(true);
  const [credit_request,setCreditRequest] = useState(false);
  const [errors,setErrors] = useState({
    receipt_type:"",
        won:"",
        price:"",
        kyat:"",
        nrc:"",
        name:"",
        phone:"",
        uploadFile:null,
        qr_image:null
  });
  const [values,setValues] = useState({
    receipt_type: "Account",// Account or Qr
    payment_type_id: payment_type_id,
        won:"",
        price:"",
        kyat:"",
        type: "Account",
        nrc:"",
        name:"",
        phone:"",
        uploadFile:null,
        qr_image:null,
        request_credit: 0
  });
  const handleAccount = () => {
        setAccount(true);
        setPassword(false);
        setValues(values => ({
            ...values,
            won: "",
        }))
        setValues(values => ({
            ...values,
            price: "",
        }))
        setValues(values => ({
            ...values,
            kyat: "",
        }))
        setValues(values => ({
            ...values,
            type: "Account",
        }))
    }
    const handlePassword = () => {
        setAccount(false);
        setPassword(true);
        setValues(values => ({
            ...values,
            won: "",
        }))
        setValues(values => ({
            ...values,
            price: "",
        }))
        setValues(values => ({
            ...values,
            kyat: "",
        }))
        setValues(values => ({
            ...values,
            type: "Password",
        }))
    }
  const handleChangeNumber = async(e) => {
    const key = e.target.id;
    const value = e.target.value;
   // console.log(value);
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    if(key === "won")
    {
        /**
         * loop array and filter for typing won
         */
        if(account === true)
        {
            console.log("Acc");
            setExchangeIsLoading(true);
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=2',{ 
                headers: { 
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
                const kyat = (value * exchangerates[0]?.price) / 1350;
                const exchangeArray = exchangerates.filter((list) => {
                    return kyat <=  list.to && list.exchange_rate_type_id == 2;
                }).sort((a,b)=>{
                    return a.price - b.price
                });
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: parseInt((value * exchangeArray[0]?.price) /1350)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: ""
                    }));
                }
                setExchangeIsLoading(false);
            }
        }else
        {
            console.log("Password");
            setExchangeIsLoading(true);
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=4',{ 
                headers: {  //wave password is 4
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
                //wave password only one price
                const exchangeArray = exchangerates.filter((list) => {
                    return  list.exchange_rate_type_id == 4;
                })
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: parseInt((value * exchangeArray[0]?.price) /1350)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: ""
                    }));
                }
                setExchangeIsLoading(false);
            }   
        }
    }
    if(key === "kyat")
    {
        /**
         * loop array and filter for typing kyat
         */
        if(account === true)
        {
            setExchangeIsLoading(true);
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=2',{ 
                headers: {  //wave password is 4
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
                const exchangeArray = exchangerates.filter((list) => {
                    return    value <=  list.to && list.exchange_rate_type_id == 2;
                }).sort((a,b)=>{
                    return a.price - b.price
                });
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        won: parseInt((values.kyat * 1350)/ exchangeArray[0]?.price)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        won: ""
                    }));
                }
                setExchangeIsLoading(false);
            }
        }else
        {
            setExchangeIsLoading(true);
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=4',{ 
                headers: {  //wave password is 4
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
                //wave password မှာ ဈေးနှုန်းတမျိုးထဲ ရှိ
                const exchangeArray = exchangerates.filter((list) => {
                    return  list.exchange_rate_type_id == 4;
                });
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        won: parseInt((values.kyat * 1350)/ exchangeArray[0]?.price)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        won: ""
                    }));
                }
                setExchangeIsLoading(false);
            }
        }
    }
  };
  const handleFetch = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat',{
                headers: { 
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
            }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
            });
        }
    }
  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    //console.log(key+"/"+value);
    setValues(values => ({
        ...values,
        [key]: value,
    }))
    
  }
    const handleFile = (file) => {
        setValues(values => ({
            ...values,
            uploadFile: file
        }));
    };
    const handleReceiptFile = (file) => {
        setValues(values => ({
            ...values,
            qr_image: file,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(values);
        await Swal.fire({
            title: "<span style='color:#000'>ငွေလွှဲမှာ သေချာလား?</span>",
            showCancelButton: true,
            width:350,
            confirmButtonText: "အတည်ပြုမည်",
        }).then(async (result) => {
            if(result.isConfirmed) {
                if(!submitted){
                    setSubmitted(true);
                    try{
                        const formData = new FormData();
                        formData.append('payment_type_id', values.payment_type_id);
                        formData.append('won', values.won);
                        formData.append('price', values.price);
                        formData.append('kyat', values.kyat);
                        formData.append('type', values.type);
                        formData.append('nrc', values.nrc);
                        formData.append('name', values.name);
                        formData.append('phone', values.phone);
                        formData.append('uploadFile', values.uploadFile);
                        formData.append('qr_image', values.qr_image);
                        formData.append('receipt_type', values.receipt_type);
                        formData.append('request_credit', values.request_credit);
                        const response = await fetch(endpointURL+"/api/v1/transactionInsert", {
                            method: 'POST',
                            headers:{
                               // 'Content-Type': 'application/json',
                                'Authorization':  'Bearer '+localStorage.getItem('token'),
                            },
                            body: formData,
                        });
                        if(!response.ok)
                        {
                            throw new Error('Request failed');
                        }
                        const resp = await response.json();
                        console.log(resp);
                        if(resp.status === true)
                        {
                            setValues({
                                    won:"",
                                    price:"",
                                    kyat:"",
                                    nrc:"",
                                    name:"",
                                    phone:"",
                                    uploadFile:null,
                                    receipt_type:"",
                                    qr_image:null
                              });
                              Swal.fire({
                                title: resp.msg,
                                width: 300, // Set the width
                              });
                              //console.log(resp.data);
                              navigate('/transaction-view', {state:resp.data});
                        }else{
                            setErrors(errors => ({
                                ...errors,
                                won: resp.data.won,
                                price:resp.data.price,
                                kyat:resp.data.kyat,
                                nrc:resp.data.nrc,
                                name:resp.data.name,
                                phone:resp.data.phone,
                                uploadFile:resp.data.uploadFile,
                                qr_image:resp.data.qr_image
                            }));
                        }
                    }catch(error){
                        Swal.fire({
                            title: error,
                            width: 300, // Set the width
                          });
                    }
                    setSubmitted(false);
                }
            }
        });  
        setIsLoading(false);
    }
    const handlePayAccount = () => {
        setPayAccount(true);
        setQr(false);
        setValues(values => ({
            ...values,
            receipt_type: "Account",
        }));
        setValues(values => ({
            ...values,
            qr_image: null,
        }));
    }
    const handleQr = () => {
        setPayAccount(false);
        setQr(true);
        setValues(values => ({
            ...values,
            receipt_type: "Qr",
        }));
    }
    const handlePaidRequest = () => {
        setPaidRequest(true);
        setCreditRequest(false);
        setValues(values => ({
            ...values,
            request_credit: 0
        }));
    }
    const handleCreditRequest = () => {
        setPaidRequest(false);
        setCreditRequest(true);
        setValues(values => ({
            ...values,
            request_credit: 1
        }));
    }
    useEffect(()=>{
        console.count("currency rete :");
       // handleFetch();
    },[]);
   // console.log(values);
    return ( 
        <>
             <KoreaBankIn />
            <form className="row" onSubmit={handleSubmit} encType="multipart/form-data">
                <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"16px"}}>Wave </p>
                <div className="row mb-3 mt-1">
                    <div className="col-6">
                        <button onClick={handleAccount} type="button" className={`btn btn-md  ${account?'btn-success':'btn-outline-success'}  w-100`}>Account</button>
                    </div>
                    <div className="col-6">
                        <button onClick={handlePassword} type="button" className={`btn btn-md  ${password?'btn-success':'btn-outline-success'} w-100`}>Password</button>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="won" style={{fontWeight:"bold"}}>သင်ပို့ငွေ  <b className="text-danger">*</b></label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark text-success"><img src="/southkorea.png" /> </span>
                            <input type="number" id="won" value={values.won} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="won" style={{color:"red"}}>{errors.won}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="price" style={{fontWeight:"bold"}}>လက်ရှိငွေလဲနှုန်း <b className="text-danger">*</b>
                        {
                            exchangeIsLoading ?<Skeleton width={80} height={10} style={{ backgroundColor: 'green',textAlign:'center' }} />:<span style={{padding:"10px"}}>&nbsp;</span>
                        }
                        </label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark"><i className="lni lni-dollar text-success"></i> </span>
                            <input type="text" id="price" readOnly value={values.price}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="price" style={{color:"red"}}>{errors.price}</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="kyat" style={{fontWeight:"bold"}}>လက်ခံငွေပမာဏ <b className="text-danger">*</b></label>
                        <div className="input-group mt-2">
                            <span className="input-group-text bg-dark text-success"><img src="/myanmar.png" /> </span>
                            <input type="number" id="kyat"  value={values.kyat} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                        </div>
                        <label htmlFor="kyat" style={{color:"red"}}>{errors.kyat}</label>
                    </div>
                </div>
                {
                    password === true ?<div className="form-group">
                                            <div className="col-12">
                                                <label htmlFor="nrc" style={{fontWeight:"bold"}}>မှတ်ပုံတင်အမှတ် <b className="text-danger">*</b></label>
                                                <div className="input-group mt-2"> 
                                                    <span className="input-group-text bg-dark"><i className="lni lni-postcard text-success"></i> </span>
                                                    <input type="text" id="nrc" value={values.nrc} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                                </div>
                                                <label htmlFor="nrc" style={{color:"red"}}>{errors.nrc}</label>
                                            </div>
                                        </div>:""
                }
                {
                    account === true ? 
                    <div className="form-group row mx-auto">
                        <div className="col-6">
                            <div className="input-group"> 
                                <button  type="button" onClick={handlePayAccount} className={`btn btn-md  ${payaccount?'btn-success':'btn-outline-success'}  w-100`}>Pay account <i className={` ${payaccount?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group"> 
                                <button  type="button" onClick={handleQr} className={`btn btn-md  ${qr?'btn-success':'btn-outline-success'} w-100`}>QR  <i className={` ${qr?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                    </div>:""
                }
                {
                    qr === true && account === true ? 
                        <div className="form-group mt-2">
                            <label style={{fontWeight:"bold"}} className="mb-2">ငွေလက်ခံ QR <b className="text-danger">*</b></label>
                            <FileUploader handleChange={handleReceiptFile} name="file" types={fileTypes}></FileUploader>
                            <label  style={{color:"red"}}>{errors.qr_image}</label>
                        </div>:
                        <>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="name" style={{fontWeight:"bold"}}>အမည် <b className="text-danger">*</b></label>
                                    <div className="input-group mt-2"> 
                                        <span className="input-group-text bg-dark"><i className="lni lni-user text-success"></i> </span>
                                        <input type="text" id="name"  value={values.name} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                    </div>
                                    <label htmlFor="name" style={{color:"red"}}>{errors.name}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="phone" style={{fontWeight:"bold"}}> ဖုန်းနံပါတ် <b className="text-danger">*</b></label>
                                    <div className="input-group mt-2"> 
                                        <span className="input-group-text bg-dark"><i className="lni lni-phone text-success"></i> </span>
                                        <input type="number" id="phone" value={values.phone} onChange={handleChangeNumber} className="form-control bg-dark text-white p-2" placeholder="09 ပါရိုက်ထည့်ပါ" autoComplete="off"></input>
                                    </div>
                                    <label htmlFor="phone" style={{color:"red"}}>{errors.phone}</label>
                                </div>
                            </div>
                        </>
                }
                
                <div className="form-group">
                    <label style={{fontWeight:"bold"}} className="mb-2">ဝမ်ငွေလွှဲ စလစ် <span style={{fontSize:"10px",color:"green"}}>(အကြွေးသည်စလစ်တွဲရန်မလို)</span></label>
                    <FileUploader handleChange={handleFile}  name="file" types={fileTypes}></FileUploader>
                    <label style={{color:"red"}}>{errors.uploadFile}</label>
                </div>
                <div className="form-group row mx-auto">
                    <div className="col-6">
                        <div className="input-group"> 
                            <button  type="button" onClick={handlePaidRequest}  className={`btn btn-md  ${paid_request?'btn-success':'btn-outline-success'}  w-100`}>လက်ငင်း <i className={` ${paid_request?'lni lni-checkmark':''}`}></i></button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-group"> 
                            <button  type="button" onClick={handleCreditRequest} className={`btn btn-md  ${credit_request?'btn-danger':'btn-outline-danger'} w-100`}>အကြွေး  <i className={` ${credit_request?'lni lni-checkmark':''}`}></i></button>
                        </div>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-success"><i className="bx bx-right-arrow-alt text-white"></i> {isLoading?"Loading..":"ရှေ့ဆက်မည်"} </button>
                    </div>
                </div>
            </form>
        </>
     );
}
 
export default Wave;