import React, {  useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import KoreaBankIn from "./KoreaBankInfo";
import Skeleton from "react-loading-skeleton";
const HomeTransfer = ({payment_type_id}) => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const fileTypes = ["JPEG","JPG", "PNG"];
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const [exchangerates,setExchangeRates] = useState([]);
    const [exchangeIsLoading,setExchangeIsLoading] = useState(false);
    const [account,setAccount] = useState(true);
    const [qr,setQr] = useState(false);
    const [paid_request,setPaidRequest] = useState(true);
    const [credit_request,setCreditRequest] = useState(false);
    const navigate = useNavigate();
    const [townships,setTownships] = useState([]);
    const [errors,setErrors] = useState({
        receipt_type:"",
        won:"",
        price:"",
        kyat:"",
        name:"",
        phone:"",
        township_id:"",
        address:"",
        uploadFile:null,
        qr_image:null
    });
    const [values,setValues] = useState({
        receipt_type: "Account",// Account or Qr
        payment_type_id:payment_type_id,
        won:"",
        price:"",
        kyat:"",
        name:"",
        phone:"",
        township_id:"",
        address:"",
        uploadFile:null,
        qr_image:null,
        request_credit: 0
  });
  const handleChangeNumber = async(e) => {
    const key = e.target.id;
    const value = e.target.value;
   // console.log(value);
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    setExchangeIsLoading(true);
        const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat?id=8',{ 
            headers: { 
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json(); 
        console.log(result.data);
        if(result.status === true)
        {
            setExchangeRates(result.data);
            if(key === "won")
            {
                /**
                 * loop array and filter for typing won
                 */
                /**
                 * get first price
                 */
                const kyat = (value * exchangerates[0]?.price) / 1350;
                const exchangeArray = exchangerates.filter((list) => {
                    return kyat <=  list.to && list.exchange_rate_type_id == 8;
                }).sort((a,b)=>{
                    return a.price - b.price
                });
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: parseInt((value * exchangeArray[0]?.price) /1350)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        kyat: ""
                    }));
                }
            }
            if(key === "kyat")
            {
                /**
                 * loop array and filter for typing kyat
                 */
                const exchangeArray = exchangerates.filter((list) => {
                    return    value <=  list.to && list.exchange_rate_type_id == 8;
                }).sort((a,b)=>{
                    return a.price - b.price
                });
                //console.log(value);
                //console.log(exchangeArray);
                /**
                 * array ၂ ခု ပေါ်လာရင် ပထမတခုပဲယူသည်
                 * backend ကလဲ ထည့်တဲ့အချိန် စစ်ဆေးရမည်
                 */
                const len = exchangeArray.length - 1;
                if(exchangeArray.length > 0)
                {
                    setValues(values => ({
                        ...values,
                        price: exchangeArray[0]?.price
                    }));
                    setValues(values => ({
                        ...values,
                        won: parseInt((values.kyat * 1350)/ exchangeArray[0]?.price)
                    }));
                }else
                { // price ကျမလာတဲ့ အချိန်မှာ blank ချိန်းထားခဲ့
                    setValues(values => ({
                        ...values,
                        price: ""
                    }));
                    setValues(values => ({
                        ...values,
                        won: ""
                    }));
                }
            }
            setExchangeIsLoading(false);
        }
    
  };
  const handleChangeDigit = async (e) => {
    const key = e.target.id;
    const value = e.target.value;
   // console.log(value);
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
        setValues(values => ({
            ...values,
            [key]: value,
        }));
    }
 }
  function handleChange(e) {
    const key = e.target.id;
    const value = e.target.value
    //console.log(key+"/"+value);
    setValues(values => ({
        ...values,
        [key]: value,
    }))
  }
    const handleFile = (file) => {
        setValues(values => ({
            ...values,
            uploadFile: file,
        }));
    };
    const handleFetch = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/townships',{
                headers: { 
                    'Content-Type': 'application/json'
                  }
              });
              const result = await response.json(); 
              if(result.status === true)
              {
                setTownships(result.data);
                setSubmitted(false);
              }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
              });
              setSubmitted(false);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await Swal.fire({
            title: "<span style='color:#000'>ငွေလွှဲမှာ သေချာလား?</span>",
            showCancelButton: true,
            width:350,
            confirmButtonText: "အတည်ပြုမည်",
        }).then(async (result) => {
            if(result.isConfirmed) {
                if(!submitted){
                    setSubmitted(true);
                    try{
                        const formData = new FormData();
                        formData.append('payment_type_id', values.payment_type_id);
                        formData.append('won', values.won);
                        formData.append('price', values.price);
                        formData.append('kyat', values.kyat);
                        formData.append('name', values.name);
                        formData.append('phone', values.phone);
                        formData.append('township_id', values.township_id);
                        formData.append('address', values.address);
                        formData.append('uploadFile', values.uploadFile);
                        formData.append('qr_image', values.qr_image);
                        formData.append('receipt_type', values.receipt_type);
                        formData.append('request_credit', values.request_credit);
                        const response = await fetch(endpointURL+"/api/v1/transactionInsert", {
                            method: 'POST',
                            headers:{
                                //'Content-Type': 'application/json',
                                'Authorization':  'Bearer '+localStorage.getItem('token'),
                            },
                            body: formData,
                        });
                        if(!response.ok)
                        {
                            throw new Error('Request failed');
                        }
                        const resp = await response.json();
                        //console.log(resp);
                        if(resp.status === true)
                        {
                            setValues({
                                    won:"",
                                    price:"",
                                    kyat:"",
                                    name:"",
                                    phone:"",
                                    township_id:"",
                                    address:"",
                                    uploadFile:null,
                                    receipt_type:"",
                                    qr_image:null
                                });
                                Swal.fire({
                                title: resp.msg,
                                width: 300, // Set the width
                                });
                                //console.log(resp.data);
                                navigate('/transaction-view', {state:resp.data});
                        }else{
                            setErrors(errors => ({
                                ...errors,
                                won: resp.data.won,
                                price:resp.data.price,
                                kyat:resp.data.kyat,
                                name:resp.data.name,
                                phone:resp.data.phone,
                                township_id:resp.data.township_id,
                                address: resp.data.address,
                                uploadFile:resp.data.uploadFile,
                                qr_image:resp.data.qr_image
                            }));
                        }
                    }catch(error){
                        Swal.fire({
                            title: error,
                            width: 300, // Set the width
                            });
                    }
                    setSubmitted(false);
                }
            }     
        });
        setIsLoading(false);
    }
    const handleFetchExchangeRate = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/exchange-rate-won-kyat',{
                headers: { 
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json(); 
            if(result.status === true)
            {
                setExchangeRates(result.data);
            }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
            });
        }
    }
    const handleAccount = () => {
        setAccount(true);
        setQr(false);
        setValues(values => ({
            ...values,
            receipt_type: "Account",
        }));
        setValues(values => ({
            ...values,
            qr_image: null,
        }));
    }
    const handleQr = () => {
        setAccount(false);
        setQr(true);
        setValues(values => ({
            ...values,
            receipt_type: "Qr",
        }));
    }
    const handleReceiptFile = (file) => {
        setValues(values => ({
            ...values,
            qr_image: file,
        }));
    };
    const handlePaidRequest = () => {
        setPaidRequest(true);
        setCreditRequest(false);
        setValues(values => ({
            ...values,
            request_credit: 0
        }));
    }
    const handleCreditRequest = () => {
        setPaidRequest(false);
        setCreditRequest(true);
        setValues(values => ({
            ...values,
            request_credit: 1
        }));
    }
    useEffect(()=>{
        if(!submitted)
        {
            setSubmitted(true);
            handleFetch();
        }
       // handleFetchExchangeRate();
    },[]);
    return ( 
        <>
                <KoreaBankIn />
                <form className="row" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"16px"}}>အိမ်အရောက်ပို့ </p>
                        <div className="col-12">
                            <label htmlFor="won" style={{fontWeight:"bold"}}>သင်ပို့ငွေ  <b className="text-danger">*</b></label>
                            <div className="input-group mt-2">
                                <span className="input-group-text bg-dark text-success"><img src="/southkorea.png" /> </span>
                                <input type="number" id="won" value={values.won} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                            </div>
                            <label htmlFor="won" style={{color:"red"}}>{errors.won}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-12">
                            <label htmlFor="price" style={{fontWeight:"bold"}}>လက်ရှိငွေလဲနှုန်း <b className="text-danger">*</b>
                                {
                                    exchangeIsLoading ?<Skeleton width={80} height={10} style={{ backgroundColor: 'green',textAlign:'center' }} />:<span style={{padding:"10px"}}>&nbsp;</span>
                                }
                            </label>
                            <div className="input-group mt-2">
                                <span className="input-group-text bg-dark"><i className="lni lni-dollar text-success"></i> </span>
                                <input type="text" id="price" readOnly value={values.price}   className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                            </div>
                            <label htmlFor="price" style={{color:"red"}}>{errors.price}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-12">
                            <label htmlFor="kyat" style={{fontWeight:"bold"}}>လက်ခံငွေပမာဏ <b className="text-danger">*</b><span style={{fontSize:"10px"}}>(သိန်း ၃၀ နှင့်အထက်သာဆောင်ရွက်ပေးပါသည်)</span></label>
                            <div className="input-group mt-2">
                                <span className="input-group-text bg-dark text-success"><img src="/myanmar.png" /> </span>
                                <input type="number"  id="kyat" value={values.kyat} onChange={handleChangeNumber}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                            </div>
                            <label htmlFor="kyat" style={{color:"red"}}>{errors.kyat}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-12">
                            <label htmlFor="township_id" style={{fontWeight:"bold"}}>မြို့ <b className="text-danger">*</b></label>
                            <div className="input-group mt-2"> 
                                <span className="input-group-text bg-dark"><i className="lni lni-home text-success"></i> </span>
                                <select id="township_id" value={values.township_id} onChange={handleChange} className="mt-0 form-select bg-dark text-white p-2">
                                    <option></option>
                                    {
                                        townships.map((township) => (
                                            <option key={township.id} value={township.id}>{township.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <label htmlFor="township_id" style={{color:"red"}}>{errors.township_id}</label>
                        </div>
                    </div>
                    <div className="form-group row mx-auto">
                        <div className="col-6" style={{textAlign:"center"}}>
                            <div className="input-group"> 
                                <button  type="button" onClick={handleAccount} className={`btn btn-md  ${account?'btn-success':'btn-outline-success'}  w-100`}>Account <i className={` ${account?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                        <div className="col-6" style={{textAlign:"center"}}>
                            <div className="input-group"> 
                                <button  type="button" onClick={handleQr} className={`btn btn-md  ${qr?'btn-success':'btn-outline-success'} w-100`}>QR  <i className={` ${qr?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                    </div>
                    
                    {
                        account ? 
                        <>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="name" style={{fontWeight:"bold"}}>အမည် <b className="text-danger">*</b></label>
                                    <div className="input-group mt-2"> 
                                        <span className="input-group-text bg-dark"><i className="lni lni-user text-success"></i> </span>
                                        <input type="text" id="name" value={values.name} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                    </div>
                                    <label htmlFor="name" style={{color:"red"}}>{errors.name}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="phone" style={{fontWeight:"bold"}}> ဖုန်းနံပါတ် <b className="text-danger">*</b></label>
                                    <div className="input-group mt-2"> 
                                        <span className="input-group-text bg-dark"><i className="lni lni-phone text-success"></i> </span>
                                        <input type="number" id="phone" value={values.phone} onChange={handleChangeDigit} className="form-control bg-dark text-white p-2"  autoComplete="off"></input>
                                    </div>
                                    <label htmlFor="phone" style={{color:"red"}}>{errors.phone}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="address" style={{fontWeight:"bold"}}>လိပ်စာ <b className="text-danger">*</b></label>
                                    <div className="input-group mt-2"> 
                                        <span className="input-group-text bg-dark"><i className="lni lni-map-marker text-success"></i> </span>
                                        <input type="text" id="address" value={values.address} onChange={handleChange}  className="form-control bg-dark text-white p-2" autoComplete="off"></input>
                                    </div>
                                    <label htmlFor="address" style={{color:"red"}}>{errors.address}</label>
                                </div>
                            </div>
                        </>:
                        <>
                            <div className="form-group mt-2">
                                <label style={{fontWeight:"bold"}} className="mb-2">ငွေလက်ခံ QR <b className="text-danger">*</b></label>
                                <FileUploader handleChange={handleReceiptFile} name="file" types={fileTypes}></FileUploader>
                                <label  style={{color:"red"}}>{errors.qr_image}</label>
                            </div>
                        </>
                    }
                    
                    <div className="form-group">
                        <label style={{fontWeight:"bold"}} className="mb-2">ဝမ်ငွေလွှဲ စလစ် <span style={{fontSize:"10px",color:"green"}}>(အကြွေးသည်စလစ်တွဲရန်မလို)</span></label>
                        <FileUploader handleChange={handleFile} name="file" types={fileTypes}></FileUploader>
                        <label  style={{color:"red"}}>{errors.uploadFile}</label>
                    </div>
                    <div className="form-group row mx-auto">
                        <div className="col-6">
                            <div className="input-group"> 
                                <button  type="button" onClick={handlePaidRequest}  className={`btn btn-md  ${paid_request?'btn-success':'btn-outline-success'}  w-100`}>လက်ငင်း <i className={` ${paid_request?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group"> 
                                <button  type="button" onClick={handleCreditRequest} className={`btn btn-md  ${credit_request?'btn-danger':'btn-outline-danger'} w-100`}>အကြွေး  <i className={` ${credit_request?'lni lni-checkmark':''}`}></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <div className="col-12" style={{textAlign:"center"}}>
                                {
                                    values.kyat < 3000000 ? 
                                        <button type="button" disabled className="btn btn-md bg-success"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading..":"ရှေ့ဆက်မည်"}</button>
                                    :
                                        <button type="submit" className="btn btn-md bg-success"><i className="bx bx-right-arrow-alt text-white"></i>{isLoading?"Loading..":"ရှေ့ဆက်မည်"}</button>
                                }
                        </div>
                    </div>
                </form>
        </>
     );
}
 
export default HomeTransfer;