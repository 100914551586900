import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
const TransactionView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [values,setValues] = useState({
        trans_id: location.state?.id,
        paid_image: null
    });
    const [errors,setErrors] = useState({
        paid_image: null
    });
    //const data = location.state;
    //console.log(data);
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
    },[]);
    console.log(location.state?.photos);
    return ( 
        <>
            <Layout2 pageType={"မှတ်တမ်းအသေးစိတ်"}>
                <Helmet>
                    <title>မှတ်တမ်းအသေးစိတ်</title>
                </Helmet>
                <div className="row mx-auto">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <img src={`./${location.state?.image}`}  style={{width:"80px",marginTop:"10px"}} alt="Logo" />
                        <h6>{location.state?.pay_name}</h6>
                        {
                            location.state?.status_id === 1? (
                                <span className='text-warning'> {location.state?.status_name} </span>
                            ):""
                        }
                        {
                            location.state?.status_id === 2? (
                                <span className='text-info'> {location.state?.status_name} </span>
                            ):""
                        }
                        {
                            location.state?.status_id === 3? (
                                <span className='text-success'> {location.state?.status_name} </span>
                            ):""
                        }
                        {
                            location.state?.status_id === 4? (
                                <span className='text-danger'> {location.state?.status_name} </span>
                            ):""
                        }
                    </div>
                </div>
                
                <Table striped  hover variant="dark" className="mt-2">
                    <tbody>
                        {
                        location.state?.qr_image === null && (location.state?.payment_type_id === 3 || location.state?.payment_type_id === 4)? (
                            <tr>
                                <td style={{width:"50%"}}>ဘဏ်အမျိုးအစား</td>
                                <td style={{width:"50%"}}>{location.state?.bank_name}</td>
                            </tr> 
                         ):""
                        } 
                        {
                        location.state?.qr_image !== null && (location.state?.payment_type_id === 3 || location.state?.payment_type_id === 4)? (
                            <tr>
                                <td style={{width:"50%"}}>ဘဏ်အမျိုးအစား</td>
                                <td style={{width:"50%"}}>{location.state?.bank_name}</td>
                            </tr> 
                         ):""
                        } 
                         { 
                            location.state?.qr_image === null && location.state?.pay_type_id ===2 &&  (location.state?.payment_type_id === 3 || location.state?.payment_type_id === 4) ? (
                            <tr>
                                <td style={{width:"50%"}}>ကိုးရီယားဘဏ် အမျိုးအစား 
                                  </td> 
                                 <td style={{width:"50%"}}> 
                                     {location.state?.korea_bank_name} 
                                 </td> 
                             </tr>  
                           ):"" 
                        }
                        {
                         location.state?.payment_type_id === 4 && location.state?.qr_image === null? (
                            <>
                                <tr>
                                    <td style={{width:"50%"}}>ဘဏ်ခွဲ/မြို့</td>
                                    <td style={{width:"50%"}}>{location.state?.township_bank_address}</td>
                                </tr> 
                                <tr>
                                    <td style={{width:"50%"}}>မှတ်ပုံတင်အမှတ်</td>
                                    <td style={{width:"50%"}}>{location.state?.nrc}</td>
                                </tr>  
                            </>   
                         ):""
                        }
                        {
                         location.state?.qr_image === null &&  location.state?.payment_type_id === 3? (
                            <>
                                <tr>
                                    <td style={{width:"50%"}}>ဘဏ် Account No</td>
                                    <td style={{width:"50%"}}>{location.state?.account_no}</td>
                                </tr> 
                            </>   
                         ):""
                        }
                        {
                            location.state?.qr_image === null && location.state?.payment_type_id === 5? (
                            <>
                                <tr>
                                    <td style={{width:"50%"}}>မြို့</td>
                                    <td style={{width:"50%"}}>{location.state?.township_name}</td>
                                </tr> 
                                <tr>
                                    <td style={{width:"50%"}}>လိပ်စာ</td>
                                    <td style={{width:"50%"}}>{location.state?.address}</td>
                                </tr> 
                            </>   
                         ):""
                        }
                        {
                            location.state?.qr_image !== null && location.state?.payment_type_id === 5? (
                            <>
                                <tr>
                                    <td style={{width:"50%"}}>မြို့</td>
                                    <td style={{width:"50%"}}>{location.state?.township_name}</td>
                                </tr> 
                            </>   
                         ):""
                        }
                        {
                            location.state?.qr_image === null ?
                            <tr>
                                <td style={{width:"50%"}}>အမည်</td>
                                <td style={{width:"50%"}}>{location.state?.name}</td>
                            </tr>:
                            <tr>
                                <td style={{width:"50%"}}>ငွေလက်ခံ QR</td>
                                <td style={{width:"50%"}}>
                                    {
                                        location.state?.qr_image !==null ?
                                        <a href={endpointURL+`/storage/${location.state?.qr_image}`} target="_blank" rel="noreferrer">ငွေလက်ခံ QR</a>
                                        :""
                                    }
                                </td>
                            </tr>
                        }
                        
                        {
                         location.state?.qr_image === null && (location.state?.payment_type_id === 1 ||  location.state?.payment_type_id === 2 ||  location.state?.payment_type_id === 5) ? (
                            <tr>
                                <td style={{width:"50%"}}>ဖုန်းနံပါတ်</td>
                                <td style={{width:"50%"}}>{location.state?.phone}</td>
                            </tr>
                         ):""
                        }
                        {
                         location.state?.payment_type_id === 1? (
                            <tr>
                                <td style={{width:"50%"}}>Wave အမျိုးအစား</td>
                                <td style={{width:"50%"}}>{location.state?.wave_type}</td>
                            </tr>
                         ):""
                        }
                        {
                         location.state?.payment_type_id === 1 && location.state?.wave_type ==="Password" ? (
                            <tr>
                                <td style={{width:"50%"}}>မှတ်ပုံတင်အမှတ်</td>
                                <td style={{width:"50%"}}>{location.state?.nrc}</td>
                            </tr>
                         ):""
                        }
                        <tr>
                            <td style={{width:"50%"}}>
                                {
                                    location.state?.pay_type_id === 3 ? "ဘတ်":"ဝမ်"
                                }
                            </td>
                            <td style={{width:"50%"}}>{location.state?.won}</td>
                        </tr> 
                        <tr>
                            <td style={{width:"50%"}}>ကျပ်</td>
                            <td style={{width:"50%"}}>{location.state?.kyat}</td>
                        </tr> 
                        <tr>
                            <td style={{width:"50%"}}>ငွေဈေး</td>
                            <td style={{width:"50%"}}>{location.state?.price}</td>
                        </tr>
                        <tr>
                            <td style={{width:"50%"}}>ရက်စွဲ/အချိန်</td>
                            <td style={{width:"50%"}}>{location.state?.date} {location.state?.time}</td>
                        </tr>
                        {
                         location.state?.payment_type_id === 1 && location.state?.wave_type ==="Password" ? (
                            <tr>
                                <td style={{width:"50%"}}>လျှို့ဝှက်အမှတ်</td>
                                <td style={{width:"50%"}}>{location.state?.wave_password}</td>
                            </tr>
                         ):""
                        }
                        {
                            location.state?.status_id === 3 && location.state?.commission > 0 ?
                            <tr>
                                <td style={{width:"50%"}}>ကော်မရှင်</td>
                                <td style={{width:"50%"}}>{location.state?.commission}</td>
                            </tr>:""
                        }
                        <tr>
                            <td style={{width:"50%"}}>မှတ်ချက်</td>
                            <td style={{width:"50%"}}>{location.state?.remark}</td>
                        </tr>
                        <tr>
                            <td style={{width:"50%"}}>ငွေရစလစ် </td>
                            <td style={{width:"50%"}}>
                                <ul>
                                    {
                                        location.state?.status_id === 3 ?
                                        location.state?.photos?.map((photo)=>{
                                            return (
                                                <li><a href={endpointURL+`/storage/${photo.image}`} target="_blank" rel="noreferrer">ငွေလွှဲ ပြေစာ </a></li>
                                            );
                                        })                                   
                                        :""
                                    }
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                
            </Layout2>
        </>
     );
}
 
export default TransactionView;