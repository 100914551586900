import React, { useEffect, useState } from 'react';
import TelegramLoginButton from 'react-telegram-login';
const TelegramLoginButtonComponent = () => {
  const [loading, setLoading] = useState(false);

    const handleTelegramResponse = (response) => {
        setLoading(false); // Stop loading once response is received
        console.log(response); // Handle the response from Telegram
        // Add your logic to handle the response (e.g., store user data, redirect, etc.)
    };

    const handleTelegramLogin = () => {
        setLoading(true); // Start loading when the button is clicked
        // Additional logic can be added here before or after setting loading state
    };

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // const element = document.querySelector('#telegram-login-pawsargyipayBot');
      // if (element) {
      //   console.log(element);
      // }
    }, 3000);
  },[]);

  return (
    <>
      {
        <>
        {
          loading ? <p>Loading...</p>:
          <TelegramLoginButton
            //dataOnauth={handleTelegramResponse}
            dataAuthUrl="https://pwaesargyipay.com/auth"
            botName="pawsargyipayBot"
            buttonSize="large"
            usePic="true"
          />
        }
        </>
      }
    </>
  );
};

export default TelegramLoginButtonComponent;
