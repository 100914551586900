import {  useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
const ThailandBathInfo = () => {
    const [bank_name,setBankName] = useState("");
    const [account_no,setAccountNo] = useState("");
    const [name,setName]   = useState("");
    const [loading,setLoading] = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const getPaymentInfo = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/getBathInfo',{
                headers: { 
                    'Content-Type': 'application/json'
                  }
              });
              const result = await response.json(); 
              if(result.status === true)
              {
                setBankName(result.data.bank_name);
                setAccountNo(result.data.account_no);
                setName(result.data.name);
              }
        }catch(error){
            Swal.fire({
                text: error.message,
                width: 450, // Set the width
              });
        }
        setLoading(false);
    }
    const copyText = async (e) => {
        const textToCopy = account_no; //bank account
        // Create a temporary input element
        const input = document.createElement('input');
        input.style.position = 'fixed';
        input.style.opacity = 0;
        input.value = textToCopy;
        // Append the input element to the DOM
        document.body.appendChild(input);
        // Select the text inside the input element
        input.select();
        // Execute the copy command
        document.execCommand('copy');
        // Remove the input element from the DOM
        document.body.removeChild(input);
        Swal.fire({
            text: "Copied account!",
            width: 300, // Set the width
            timer: 1000, // Time in milliseconds
            showConfirmButton: false // Hide the confirm button
          });
   };
    useEffect(()=>{
        console.count("korea bank info :");
        setLoading(true);
        if(!submitted)
        {
            setSubmitted(true);
            getPaymentInfo();
        }
       
    },[]);
    return ( 
        <>
            <h6>ထိုင်းအကောင့်သို့ငွေလွှဲပါ</h6>
            <Button  variant="p-1" style={{border:"1px solid green"}}>  
                <table style={{width:"100%"}}>
                    <tr>
                        <td style={{width:"50%"}}>{loading? <Skeleton width={80} height={10} style={{ backgroundColor: '#0f0f0f' }} />: bank_name}</td>
                        <td style={{width:"50%"}}>{loading? <Skeleton width={80} height={10} style={{ backgroundColor: '#0f0f0f' }} />:name}</td>
                    </tr>
                    <tr>
                        <td style={{width:"50%"}}>{loading? <Skeleton width={80} height={10} style={{ backgroundColor: '#0f0f0f' }} />:account_no}</td>
                        <td style={{width:"50%"}}><button onClick={copyText} style={{marginLeft:"30px"}}  className="btn btn-sm btn-outline-success">Copy</button></td>
                    </tr>
                </table>
            </Button>
        </>
    );
}
 
export default ThailandBathInfo;