import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Stack } from "react-bootstrap";
import KyatPayWonYu from "../components/KyatPayWonYu";
import Swal from "sweetalert2";
const KyatToWonForm = () => {
    /**
     * ကျပ်ပေးဝမ်ယူ
     */
    const navigate = useNavigate();
    const {id} = useParams();
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
    },[]);
    return ( 
        <>
             <Layout2 pageType={"ကျပ်ပေးဝမ်ယူ"}>
                <Helmet>
                    <title>KyatToWon - Form</title>
                </Helmet>
                <div className='row'>
                    <div className='col-12'>
                        <Link to={"/kyat-to-won"} style={{color:"rgb(204,204,204)",cursor:"pointer",fontSize:"13px"}}>
                            <i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> 
                            အဆင့် ၁ သို့
                        </Link>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",marginTop:"5px"}}>အချက်အလက်များဖြည့်သွင်းပါ </p>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"10px"}}>အဆင့် ၂ မှ ၂ </p>
                    </div>
                    <Stack gap={1} className="mt-1 mx-auto">
                         <KyatPayWonYu bank_id={id} />
                    </Stack>
                </div>
             </Layout2>
        </>
     );
}
 
export default KyatToWonForm;