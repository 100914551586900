import {  useNavigate, useSearchParams } from "react-router-dom";
import Layout2 from "../layouts/Layout2";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
const Auth = () => {
  const navigate = useNavigate();
  const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
  const [searchParams] = useSearchParams();
  const [isLoading,setIsLoading] = useState(false);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const registerArray = {
        "id" : searchParams.get('id'),
        "first_name" : searchParams.get('first_name'),
        "last_name"  : searchParams.get("last_name"),
        "photo_url"  : searchParams.get("photo_url")
    };
    const [values,setValues] = useState({
        id : searchParams.get('id'),
        first_name : searchParams.get('first_name'),
        last_name  : searchParams.get("last_name"),
        photo_url  : searchParams.get("photo_url"),
        telegram_phone  : ""
    });
    console.log(values);
  const loginTelegram = async () => {
    try{
        const response = await fetch(endpointURL+"/api/v1/authLogin", {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registerArray),
        });
        if(!response.ok)
        {
            throw new Error('Request failed');
        }
        const resp = await response.json();
        console.log(resp);
        if(resp.status === true)
        {
           setIsLoading(false);
           localStorage.setItem('authenticated',true);
           localStorage.setItem('token', resp.token);
           localStorage.setItem('name', resp.data.name);
           localStorage.setItem('username', resp.data.username);
           localStorage.setItem('unit', resp.data.unit);
           Swal.fire({
            text: "ပွဲစားကြီး Pay မှ ကြိုဆိုပါတယ်...",
            width: 450, // Set the width
           });
           navigate("/");
        }else{
            setIsLoading(false);
            Swal.fire({
                title: "Telegram phone ထည့်ပါ ",
                width: 450, // Set the width
            });
        }
    }catch(error){
        Swal.fire({
           text: "Login ပြန်ဝင်ပါ ",
            width: 450, // Set the width
        });
        console.error('Login failed: '+error.message);
    }
  } 
  const firstLoginTelegram = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    try{
        const response = await fetch(endpointURL+"/api/v1/authLogin", {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
        });
        if(!response.ok)
        {
            throw new Error('Request failed');
        }
        const resp = await response.json();
        console.log(resp);
        
        if(resp.status === true)
        {
           setIsLoading(false);
           localStorage.setItem('authenticated',true);
           localStorage.setItem('token', resp.token);
           localStorage.setItem('name', resp.data.name);
           localStorage.setItem('username', resp.data.username);
           localStorage.setItem('unit', resp.data.unit);
           Swal.fire({
            text: "ပွဲစားကြီး Pay မှ ကြိုဆိုပါတယ်...",
            width: 450, // Set the width
           });
           navigate("/");
        }else{
            setIsLoading(false);
            Swal.fire({
                text : "Telegram phone ထည့်ပါ ",
                width: 450, // Set the width
            });
        }
        setIsSubmitted(false);
    }catch(error){
        setIsSubmitted(false);
        Swal.fire({
            text: "Login ပြန်ဝင်ပါ ",
            width: 450, // Set the width
        });
        console.error('Login failed: '+error.message);
    }
    
  } 
  const handleChangeNumber = (e) => {
    const key = e.target.id;
    const value = e.target.value;
   console.log(key +"/"+value);
    // Allow only numbers and a single decimal point
    if (/^\d*\+?\d*$/.test(value)) {
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
  }
    useEffect(() => {
        setIsLoading(true);
        loginTelegram();
    },[]);
    return ( 
        <Layout2 pageType={"Login ဝင်ရောက်နေသည်"}>
            <Helmet>
                <title>Login </title>
            </Helmet>
            { isLoading ? (
                        <>
                            <div className='col-12' style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> Login ဝင်ရောက်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </>
                    ):
                    (
                        <form className="row mt-4" onSubmit={firstLoginTelegram}>
                            <div className="form-group">
                                <div className="col-12">
                                    <label htmlFor="telegram_phone"> Telegram phone (နိုင်ငံကုဒ်ပါထည့်ပါ)(ex: +95) <b className="text-danger">*</b></label>
                                    <input type="text" id="telegram_phone" value={values.telegram_phone} onChange={handleChangeNumber} className="form-control bg-dark text-white mt-2" autoComplete="off"></input>
                                    <label htmlFor="telegram_phone" style={{color:"red"}}></label>
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <div className="col-12" style={{textAlign:"center"}}>
                                    <button type="submit" className="btn btn-md bg-success">
                                        <i className="bx bx-right-arrow-alt text-white"></i>
                                        {isSubmitted? "Loading..":"အတည်ပြု" } 
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
            }
        </Layout2>
     );
}
 
export default Auth;