import { Link,useNavigate } from "react-router-dom";
import Layout2 from "../layouts/Layout2";
import { Helmet } from "react-helmet";
// import {LoginSocialFacebook} from "reactjs-social-login";
// import { FacebookLoginButton } from "react-social-login-buttons";
import TelegramLoginButtonComponent from "../components/TelegramLoginButton";
const Profile = () => {
    const navigate = useNavigate();
    let myStyle ={
        color:"rgb(204,204,204)",
        borderBottom:"1px dashed #ccc",
        padding:"10px",
        textDecoration:"none"
    };
    const clearSession = (e) => {
        localStorage.removeItem("token");
        localStorage.removeItem('authenticated');
        localStorage.removeItem('frist_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('telegramAuthToken');
        navigate("/");
    };
    return (
        <Layout2 pageType={"Profile"}>
            <Helmet>
                <title>Profile</title>
            </Helmet>   
            <div style={{textAlign:"center"}}>
                <TelegramLoginButtonComponent />
            </div>
            {
                localStorage.getItem("authenticated") ? 
                    <div className='row mt-2 p-3'>
                        <Link to={"/commission-payment"} className='col-12' style={myStyle}>
                            <i className='bx bx-dollar'></i> ကော်မရှင် လက်ခံအကောင့်
                        </Link>
                        <Link to={"/commission-withdrawl"} className='col-12' style={myStyle}>
                            <i className='bx bx-dollar'></i> ထုတ်ယူပြီး ကော်မရှင်
                        </Link>
                        <a onClick={clearSession} className='col-12' style={myStyle}>
                            <i className='bx bx-log-out'></i> စနစ်မှထွက်မည်
                        </a>
                    </div>:
                    <>
                        <h5 style={{textAlign:"center"}}>Telegram ဖြင့် Login လမ်းညွှန်</h5>
                        <ul>
                            <li>Login in with Telegram Button အားနှိပ်ပါ</li>
                            <li>နိုင်ငံရွေးပြီး Telegram ဖုန်းနံပါတ် ထည့်ပါ</li>
                            <li>Telegram App ရှိ Telegram Chat တွင် Confirm Button အားနှိပ်ပါ</li>
                            <li>ပထမဆုံးအကြိမ် Login ဝင်ခြင်းအတွက် Telegram ဖုန်းနံပါတ်အား နိုင်ငံကုဒ်နှင့်တကွ ထပ်မံဖြည့်ပါ</li>
                            <li>အတည်ပြု Button အားနှိပ်ပါ</li>
                        </ul>
                    </>    
            }
            <div style={{width:"100%",marginTop:"30px"}}>
                <center>Version 1.0.1</center>
            </div>
        </Layout2>
      );
}
 
export default Profile;