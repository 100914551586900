import { useEffect, useState } from "react";
import Layout2 from "../layouts/Layout2";
import {  useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
const CommissionPayment = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [unit,setUnit] = useState(0);
    const [submitted,setSubmitted] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handlePaymentInfo = async (e) => {
        e.preventDefault();
        const paymentInfoArray = {
            "name":name,
            "phone":phone
        };
        setIsLoading(true);
        if(!submitted){
            setSubmitted(true);
            try{
                const response = await fetch(endpointURL+"/api/v1/commission-payment-insert",{
                    method:"POST",
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization':  'Bearer '+localStorage.getItem('token'),
                    },
                    body: JSON.stringify(paymentInfoArray),
                });
                const resp = await response.json();
                if(response.status == 200)
                {
                    if(resp.status === true)
                    {
                        Swal.fire({
                            text: "အောင်မြင်ပါသည်",
                            width: 350, // Set the width
                          });
                    }else
                    {
                        
                        Swal.fire({
                            text: "Something wrong!",
                            width: 350, // Set the width
                          });
                    }
                }else{
                    Swal.fire({
                        title: "Error",
                        width: 350, // Set the width
                      });
                }
            }catch(error){
                console.error('register failed: ',error.message);
            }
            setSubmitted(false);
        }
        setIsLoading(false);
    }
    const handleCommissionPayment = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/commission-payment',{
                  headers:{
                        'Content-Type': 'application/json',
                        'Authorization':  'Bearer '+localStorage.getItem('token'),
                    },
              });
              const result = await response.json(); 
              if(result.status === true)
              {
                setName(result.data["kpay_name"]);
                setPhone(result.data["kpay_phone"]);
                setUnit(result.data["unit"]);
              }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
              });
        }
    }
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
        handleCommissionPayment();
    },[]);
    return ( 
        <Layout2 pageType={"ငွေလက်ခံအကောင့်"}>
            <Helmet>
                <title>ငွေလက်ခံအကောင့်</title>
            </Helmet>
            <p style={{color:"#eee",textAlign:"center",fontWeight:"bold"}}>
                ကော်မရှင်ထုတ်ရန် Kpay အချက်အလက်ဖြည့်ပါ
            </p>
            <div className='col-12' style={{textAlign:"center"}}>
               မိမိကော်မရှင် <button className="btn btn-outline-success"  style={{marginRight:"9px",marginBottom:"10px",width:"50%"}}>{unit} MMK</button>
            </div>
            <form className="row" onSubmit={handlePaymentInfo}>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="name">ငွေလက်ခံအကောင့် အမည် <b className="text-danger">*</b></label>
                        <input type="text" id="name" defaultValue={name} onChange={(e) => setName(e.target.value)} className="form-control bg-dark text-white mt-2" placeholder="အမည်ရိုက်ထည့်ပါ" autoComplete="off"></input>
                        <label htmlFor="name" style={{color:"red"}}></label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-12">
                        <label htmlFor="phone"> ငွေလက်ခံဖုန်းနံပါတ်(09 ထည့်ရန်) <b className="text-danger">*</b></label>
                        <input type="number" id="phone" defaultValue={phone} onChange={(e) => setPhone(e.target.value)} className="form-control bg-dark text-white mt-2" autoComplete="off"></input>
                        <label htmlFor="phone" style={{color:"red"}}></label>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <div className="col-12" style={{textAlign:"center"}}>
                        <button type="submit" className="btn btn-md bg-success">
                            <i className="bx bx-right-arrow-alt text-white"></i>
                            {isLoading?"Loading":"အတည်ပြု"}
                        </button>
                    </div>
                </div>
            </form>
        </Layout2>
     );
}
 
export default CommissionPayment;