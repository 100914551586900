import { useEffect, useState } from "react";
import { Button, Spinner, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";
import Swal from "sweetalert2";

const Currency = () => {
    const [data,setData] = useState([]);
    const [date,setDate] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const handleFetch = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/exchange-rate',{
                headers: { 
                    'Content-Type': 'application/json'
                  }
              });
              const result = await response.json(); 
              if(result.status === true)
              {
                setData(result.data);
                setDate(result.date);
                setIsLoading(false);
              }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
              });
        }
    }
    useEffect(()=>{
        setIsLoading(true);
        handleFetch();
    },[])
    return ( 
        <Layout2 pageType="ငွေလဲလှယ်နှုန်းများ">
                <Helmet>
                    <title>ငွေလဲလှယ်နှုန်းများ</title>
                </Helmet>
                <div>
                    <h6 className="mb-3 mt-3">{date} ရက်နေ့ ငွေလဲလှယ်နှုန်းများ</h6>
                    {
                        isLoading ? 
                            <div style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        :
                        data.length > 0 ? 
                            <Stack gap={2} className="mt-2 mx-auto" >
                                {data.map((list,index)=>(
                                        <Button key={index} variant="outline-secondary   p-1">  
                                            <h6 style={{fontWeight:"bold",color:"white"}}>{list.name}</h6>
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td style={{width:"33%"}}>{list.exchange_rate_type_id == 2 || list.exchange_rate_type_id == 8 ? list.to+" အထိ":""}</td>
                                                    <td style={{width:"33%",fontWeight:"bold"}} className="text-success">{"ဈေးနှုန်း "+list.price}</td>
                                                </tr>
                                            </table>
                                        </Button>
                                    ))
                                }
                            </Stack>
                        :""
                    }
                </div>
            </Layout2>
     );
}
 
export default Currency;