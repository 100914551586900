import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import WonToKyat from './pages/WonToKyat';
import WonToKyatForm from './pages/WonToKyatForm';
import Transaction from './pages/Transaction';
import Currency from './pages/Currency';
import Profile from './pages/Profile';
import AppDownload from './pages/AppDownload';
import TransactionView from './pages/TransactionView';
import KyatToWon from './pages/KyatToWon';
import KyatToWonForm from './pages/KyatToWonForm';
import Credit from './pages/Credit';
import CommissionPayment from './pages/CommissionPayment';
import CommissionWithdrawl from './pages/CommissionWithdrawl';
import Auth from './pages/Auth';
import IosGuide from './pages/IosGuide';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/won-to-kyat" element={<WonToKyat />}></Route>
                <Route path="/won-to-kyat-form/:id" element={<WonToKyatForm />}></Route>
                <Route path="/transaction" element={<Transaction />}></Route>
                <Route path='/currency' element={<Currency />}></Route>
                <Route path='/profile' element={<Profile />}></Route>
                <Route path='/app-download' element={<AppDownload />}></Route>
                <Route path='/transaction-view' element={<TransactionView />}></Route>
                <Route path="/kyat-to-won" element={<KyatToWon />}></Route>
                <Route path="/kyat-to-won-form/:id" element={<KyatToWonForm />}></Route>
                <Route path='/credit' element={<Credit />}></Route>
                <Route path='/commission-payment' element={<CommissionPayment />}></Route> 
                <Route path='/commission-withdrawl' element={<CommissionWithdrawl />}></Route>
                <Route path='/auth/:id?&:first_name?&:last_name?&:photo_url?' element={<Auth />}></Route> 
                <Route path='/ios-guide' element={<IosGuide />}></Route> 
            </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

