import Layout2 from "../layouts/Layout2";
import { Helmet } from "react-helmet";
const IosGuide = () => {
    return (
        <Layout2 pageType={"Profile"}>
            <Helmet>
                <title>Profile</title>
            </Helmet>   
            <h4>iPhone မှ ပွဲစားကြီး Pay ဒေါင်းလော့လုပ်နည်း</h4>
            <p>အဆင့်(၁) လူကြီးမင်း၏ iPhone မှ Safari ကိုဖွင့်လိုက်ပါ။ Safari ထည့်မှ https://pwaesargyipay.com ကိုရိုက်ထည့်ပါ။ ပုံတွင်ပြထားသည့်အတိုင်းဆက်နှိပ်ပါ</p>
            <img src="1.png" style={{width:"100%"}} alt="Logo"></img>

            <p>အဆင့်(၂)  Add To Home Screen ကိုနှိပ်ပါ</p>
            <img src="2.jpg" style={{width:"100%"}} alt="Logo"></img>
            <p>အဆင့်(၃) Add ကိုဆက်နှိပ်ပါ</p>
            <img src="3.png" style={{width:"100%"}} alt="Logo"></img>
            <p>အဆင့်(၄) လူကြီးမင်း၏ iPhone တွင် ပွဲစားကြီး Pay App ကိုထည့်သွင်းပြီးဖြစ်ပါသည်</p>
            <img src="4.jpg" style={{width:"100%"}} alt="Logo"></img>
        </Layout2>
      );
}
export default IosGuide;