import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";

const AppDownload = () => {
    return ( 
        <Layout2 pageType="အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ">
            <Helmet>
                <title>App Download</title>
            </Helmet>
            <div className="row">
                <div className="col-12 mb-3 mt-4" style={{textAlign:"center"}}>
                    <img src="logo.png" style={{width:"150px"}} alt="Logo"></img>
                </div>
                <div className="col-12" style={{textAlign:"center"}}>
                    <h4>အက်ပ်များကို ဒေါင်းလုဒ်လုပ်ပါ</h4>
                </div>
                <div className="col-12 mt-4" style={{textAlign:"center"}}>
                    <a href='/ios-guide' className='btn btn-primary' style={{marginRight:"20px"}}><i className='lni lni-apple'></i> iOS</a>
                     <a href='https://office.pwaesargyipay.com/apk/pwaesargyipay.apk' className='btn btn-primary'  download><i className='lni lni-android'></i> Android</a> 
                    
                </div>
            </div>
        </Layout2>
     );
}
 
export default AppDownload;