import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import 'aos/dist/aos.css';
import { isMobile,isDesktop } from 'react-device-detect';
const Layout = ({children}) => {
    useEffect(() => {
        AOS.init();
      }, []);
    return (  
        <div className="content">
            <Header></Header>
                <main className="page-body" style={{marginTop:isMobile?"30px":"",minHeight:isDesktop?"98vh":"98vh",maxHeight:isDesktop?"98vh":"98vh"}}>
                    {children}
                </main>
            <Footer></Footer>
        </div>
    );
}
 
export default Layout;