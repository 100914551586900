import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout2 from "../layouts/Layout2";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wave from "../components/Wave";
import { Stack } from "react-bootstrap";
import Kpay from "../components/Kpay";
import Banking from "../components/Banking";
import BankTransfer from "../components/BankTransfer";
import HomeTransfer from "../components/HomeTransfer";
import Bath from "../components/Bath";
import Swal from "sweetalert2";
const WonToKyatForm = () => {
    const navigate = useNavigate();
    /**
     * payment အမျိုးအစားကနေ component ခွဲထုတ်သည်
     */
    const {id} = useParams();
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
    },[]);
    return ( 
        <>
             <Layout2 pageType={id === "6" ?"ဘတ်ပေးကျပ်ယူ":"ဝမ်ပေးကျပ်ယူ.."}>
                <Helmet>
                    <title>WonToKyat - Form</title>
                </Helmet>
                <div className='row'>
                    <div className='col-12'>
                        <Link to={"/won-to-kyat"} style={{color:"rgb(204,204,204)",cursor:"pointer",fontSize:"13px"}}>
                            <i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> 
                            အဆင့် ၁ သို့
                        </Link>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",marginTop:"5px"}}>အချက်အလက်များဖြည့်သွင်းပါ </p>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"10px"}}>အဆင့် ၂ မှ ၂ </p>
                    </div>
                    <Stack gap={1} className="mt-1 mx-auto">
                        {
                            id === "1"? (
                                <>
                                    <Wave  payment_type_id={1} />
                                </>
                            ) :""
                        }
                        {
                            id === "2"? <Kpay payment_type_id={2} />:""
                        }
                        {
                            id === "3"? <Banking payment_type_id={3} /> :""
                        }
                        {
                            id === "4"? <BankTransfer payment_type_id={4} />:""
                        }
                        {
                            id === "5"? <HomeTransfer payment_type_id={5} /> :""
                        }
                        {
                            id === "6" ?<Bath payment_type_id={6} />:"" // backend မှာဖမ်းထားလို့ 6 ထည့် / banking နဲ့တူ
                        }

                    </Stack>
                </div>
             </Layout2>
        </>
     );
}
 
export default WonToKyatForm;