import React,{ useEffect, useState } from 'react';
import Layout2 from '../layouts/Layout2';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import { Spinner } from 'react-bootstrap';
const WonToKyat = () => {
    const navigate = useNavigate();
    const [payments,setPayment] = useState([]);
    const [isLoading,setIsLoading]  = useState(false);
    const [submitted,setSubmitted] = useState(false);
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const handleFetch = async (e) => {
        try{
            const response = await fetch(endpointURL+'/api/v1/payment-type',{
                headers: { 
                    'Content-Type': 'application/json'
                  }
              });
              const result = await response.json(); 
              if(result.status === true)
              {
                setPayment(result.data);
                setIsLoading(false);
              }
        }catch(error){
            Swal.fire({
                title: error.message,
                width: 450, // Set the width
              });
        }
    }
    useEffect(() => {
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
        if(!submitted)
        {
            setIsLoading(true);
            setSubmitted(true);
            handleFetch();      
            console.count("Render Count: ")
        } 
    },[])
    let imgSize= {
        width:"100%",
        boxShadow: "0 5px 10px #888888",
        borderRadius:"10px",
        backgroundColor:"#eee"
    }; 
   // console.log(payments);
    return ( 
        <>
            <Layout2 pageType={"ဝမ်ပေးကျပ်ယူ"}>
                <Helmet>
                    <title>WonToKyat - Payment</title>
                </Helmet>
                <div className='row'>
                    <div className='col-12'>
                        <Link to={"/"} style={{color:"rgb(204,204,204)",cursor:"pointer",fontSize:"13px"}}>
                            <i className="bx bx-arrow-back" style={{color:"rgb(204,204,204)"}}></i> 
                           မူလစာမျက်နှာ သို့
                        </Link>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",marginTop:"5px"}}>ငွေလွှဲအမျိုးအစားကို ရွေးပြီး ငွေလွှဲပါ </p>
                        <p style={{color:"rgb(204,204,204)",textAlign:"center",fontSize:"10px"}}>အဆင့် ၂ မှ ၁ </p>
                    </div>
                    { isLoading ? (
                        <>
                            <div className='col-12' style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        </>
                    ):(
                            payments.map((payment,index) => (
                                <div key={payment.id} className='col-4' style={{textAlign:"center"}}>
                                    <Link to={"/won-to-kyat-form/"+payment.id} className='link' style={imgSize}>
                                        <img src={payment.image}  alt='lucky type' style={{height:"100px"}}></img>
                                    </Link>
                                    <p style={{color:"#ccc",fontWeight:"bold"}}>{payment.name}</p>
                                </div>
                            ))
                        )
                    }
                </div>
            </Layout2>
        </>
     );
}
 
export default WonToKyat;