import {   Button, Modal, Spinner } from 'react-bootstrap';
import Layout2 from '../layouts/Layout2';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import KoreaBankInfo from "../components/KoreaBankInfo";
import { FileUploader } from "react-drag-drop-files";
const Credit = () => {
    const endpointURL =process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE;
    const [isLoading,setIsLoading] = useState(false);
    const fileTypes = ["JPEG","JPG", "PNG"];
    const [submitted,setSubmitted] = useState(false);
    const [data,setData] = useState([]);
    const [creditWon,setCreditWon] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const [errors,setErrors] = useState({
        uploadFile:null,
    });
    const [values,setValues] = useState({
        uploadFile:null,
  });
    const fetchData = async () => {
        setIsLoading(true);
        try{
            const response = await fetch(endpointURL+"/api/v1/getCreditTransactions",{
                method:"POST",
                headers:{
                     'Content-Type': 'application/json',
                     'Authorization':  'Bearer '+localStorage.getItem('token'),
                 },
                body: JSON.stringify({}),
            });
            if(!response.ok)
            {
               console.log("error resp");
            }
            const result = await response.json();
            if(result.status == true)
            {
                setData(result.data);
                setCreditWon(result.creditWon);
                //setCreditKyat(result.creditKyat);
                // setCreditBath(result.creditBath);
            }else
            {
                console.log("response fail");
            }
        }catch(error){
            console.log("error catch")
        }
        setIsLoading(false);
    }
    const viewTransaction = (data) => {
        navigate('/transaction-view', {state:data});
    }
    const toPaidCredit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(values.uploadFile);
        await Swal.fire({
            title: "<span style='color:#000'>အကြွေးဆပ်မှာလား?</span>",
            showCancelButton: true,
            width:350,
            confirmButtonText: "အတည်ပြုမည်",
        }).then(async (result) => {
            if(result.isConfirmed) {
                if(!submitted){
                    setSubmitted(true);
                    try{
                        const formData = new FormData();
                        formData.append('uploadFile', values.uploadFile);
                        const response = await fetch(endpointURL+"/api/v1/toPaidCredit", {
                            method: 'POST',
                            headers:{
                                //'Content-Type': 'application/json',
                                'Authorization':  'Bearer '+localStorage.getItem('token'),
                            },
                            body: formData,
                        });

                        if(!response.ok)
                        {
                            throw new Error('Request failed');
                        }
                        const resp = await response.json();
                        //console.log(resp);
                        if(resp.status === true)
                        {
                            setShow(false);
                            setValues({
                                    uploadFile:null,
                              });
                              Swal.fire({
                                title: resp.msg,
                                width: 300, // Set the width
                              });
                              fetchData();
                        }else{
                            setErrors(errors => ({
                                ...errors,
                                uploadFile:resp.data.uploadFile
                            }));
                        }
                    }catch(error){
                        Swal.fire({
                            title: error,
                            width: 300, // Set the width
                          });
                    }
                    setSubmitted(false);
                }
            }
        });
        setIsLoading(false);
    }
    const handleFile = (file) => {
        setValues(values => ({
            ...values,
            uploadFile: file,
        }));
    };
    useEffect(()=>{
        if(localStorage.getItem('token') == null || localStorage.getItem('token')=="")
        {
            Swal.fire({
                text: "Telegram ဖြင့် Login ဝင်ပါ",
                width: 400, // Set the width
                });
            navigate("/");
        }
        fetchData();
    },[]);
    return ( 
        <>
            <Layout2 pageType="အကြွေးစာရင်းများ">
                <Helmet>
                    <title>အကြွေးစာရင်းများ</title>
                </Helmet>
               
                <h6>အကြွေးစာရင်းများ</h6>
                    <div className='col-12' style={{textAlign:"center"}}>
                        <button className="btn btn-outline-success"  style={{marginRight:"9px",marginBottom:"10px",width:"45%"}}>{creditWon} ₩</button>
                        <button className="btn btn-outline-success" onClick={handleShow}  style={{marginRight:"9px",marginBottom:"10px",width:"45%"}}>အကြွေးဆပ်မည်</button>
                    </div>
                    {
                        isLoading ? 
                            <div style={{textAlign:"center"}}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>  လုပ်ဆောင်နေသည်...ခဏစောင့်ပါ
                            </div>
                        :
                            data.length > 0 ?
                                data.map((list)=>(
                                    <table key={list.id} onClick={(e) => viewTransaction(list)} className="table table-borderless outline-secondary" border="0" style={{width:"100%",borderRadius:"20px",fontSize:"13px"}} >
                                        <tbody>
                                            <tr>
                                                <td rowSpan={2} style={{maxWidth:"30px",verticalAlign:"center"}}><img src={`./${list.image}`} style={{width:"40px",marginTop:"3px"}} alt="Logo"></img></td>
                                                <td rowSpan={1} style={{textAlign:"center",minWidth:"40%"}}>
                                                    {list.pay_name}
                                                    <span style={{color:"green",marginLeft:"6px"}}>
                                                        {
                                                        list.status_id == "2"  && list.credit == "0" ? " ( Paid )":""
                                                        }
                                                        {
                                                        list.status_id == "3"  && list.credit == "0" ? " ( Paid )":""
                                                        }
                                                    </span>
                                                    <span style={{color:"red",marginLeft:"6px"}}>  
                                                        {
                                                        list.status_id == "2"  && list.credit == "1" ? " ( Credit )":""
                                                        }
                                                        {
                                                        list.status_id == "3"  && list.credit == "1" ? " ( Credit )":""
                                                        }
                                                    </span>
                                                </td>
                                                <td rowSpan={1} style={{textAlign:"right"}}>{list.kyat} MMK</td>
                                            </tr>
                                            <tr>
                                                <td rowSpan={1} style={{textAlign:"center"}}>{list.date} {list.time} {list.credit === 1 && list.paid_token !== null?<span className='text-danger'>ငွေလွှဲပြီး</span>:""} </td>
                                                <td rowSpan={1} style={{textAlign:"right",color:list.status_id == 1?"red":list.status_id == 2?"green":list.status_id == 3?"green":"red"}}>
                                                    {list.status_name}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                )) 
                        :
                        (
                            <div style={{textAlign:"center"}}>အကြွေးစာရင်းမရှိပါ...</div>
                        )
                    }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header
                style={{ background: "#0f0f0f", color: "#ccc", textAlign: "center" }}
                >
                    <Modal.Title>
                       အကြွေးဆပ်ရန် အချက်လက်ဖြည့်သွင်းပါ
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={toPaidCredit}>
                    <Modal.Body style={{ background: "#1e2129", color: "white",width:"100%" }}>
                        <KoreaBankInfo />
                        <hr />
                        <div className="form-group">
                            <label style={{fontWeight:"bold"}} className="mb-2">အကြွေးဆပ် ဝမ်ငွေလွှဲစလစ် <b className="text-danger">*</b></label>
                            <FileUploader handleChange={handleFile} name="file" types={fileTypes}></FileUploader>
                            <label  style={{color:"red"}}>{errors.uploadFile}</label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ background: "#0f0f0f", textAlign: "center" }}>
                        <Button type="submit" variant="success"><i className="bx bx-save text-white"></i>{isLoading ?"Loading...":"ပေးပို့မည်"} </Button>
                        <Button variant="danger" onClick={handleClose}>
                            ပိတ်မည်
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            </Layout2>
        </>
     );
}
 
export default Credit;