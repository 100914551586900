import React from "react";
import { Link } from 'react-router-dom';
import { isMobile,isDesktop } from 'react-device-detect';
const Footer = () => {
    return ( 
        <nav class="navbar fixed-bottom navbar-light bg-dark" style={{marginBottom:isMobile?"25px":""}}>
            <div class="container-fluid" style={{width:"100%",textAlign:"center"}}>
                <a class="nav-link" href="/" style={{fontSize:"12px",width:"25%"}}>
                    <i className="lni lni-home"></i><br></br>
                    မူလ
                </a>
                <a class="nav-link" href="/transaction" style={{fontSize:"12px",width:"25%"}}>
                    <i className="lni lni-wallet"></i><br></br>
                    ငွေလွှဲ
                </a>
                <a class="nav-link" href="/currency" style={{fontSize:"12px",width:"25%"}}>
                    <i className="lni lni-alarm"></i><br></br>
                    ငွေလွှဲနှုန်း
                </a>
                <a class="nav-link" href="/profile" style={{fontSize:"12px",width:"25%"}}>
                    <i className="lni lni-user"></i><br></br>
                    ကျွန်ုပ်
                </a>
            </div>
        </nav>
        // <nav className="mpage-footer">
        //     <Link to={"/"} className="mlink"  >
        //             <i className="lni lni-home"></i><br></br>
        //             မူလ
        //     </Link>	
        //     <Link to={"/transaction"} className="mlink">
        //             <i className="lni lni-wallet"></i><br></br>
        //             ငွေလွှဲ
        //     </Link>	
        //     <Link to={"/currency"} className="mlink">
        //             <i className="lni lni-wallet"></i><br></br>
        //             ငွေလွှဲနှုန်း
        //     </Link>	
        //     <Link to={"/profile"} className="mlink">
        //             <i className="lni lni-wallet"></i><br></br>
        //             ကျွန်ုပ်
        //     </Link>		
        // </nav> 
     );
}
 
export default Footer;